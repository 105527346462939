import React from 'react'
import { SliderBody, SampleSlide } from './styles';

export default function index() {
    return (
        <SliderBody>
            <SampleSlide/>
            <SampleSlide/>
            <SampleSlide/>
            <SampleSlide/>
            <SampleSlide/>
            <SampleSlide/>
        </SliderBody>
    )
}
