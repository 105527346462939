import React from 'react'
import { RecomBody , SampleRecom} from './styles';


export default function RecommendationSkeleton() {
    return (
        <RecomBody>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
            <SampleRecom/>
        </RecomBody>
    )
}
